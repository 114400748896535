<template>
  <div id="address">
    <van-nav-bar
      :title="$t('app.address_manage')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-tabs v-model="active">
     <van-tab :title="$t('address.sender_address')" name="sender">
       <address-list :switchable="false" type="sender" />
     </van-tab>
     <van-tab :title="$t('address.receiver_address')" name="receiver">
       <address-list :switchable="false" type="receiver" />
     </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import AddressList from '@/components/address/AddressList';

export default {
  name: 'Address',
  data() {
    return {
      active: 'sender',
    };
  },
  components: {
    AddressList,
  },
};
</script>
